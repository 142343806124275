import React, { useContext } from 'react'
import PageContext from '@context'
import { Box } from '@material-ui/core'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import { H4, MarkdownHandler } from '@system'
import { Link as GatsbyLink } from 'gatsby'
import startsWith from 'lodash/startsWith'
import { multilinkToUrl } from '@helpers'
import Img from '@system/img'

const useStyles = makeStyles((theme) => ({
  cardOuter: ({ cardModuleMaxWidth }) => ({
    height: '100%',
    backgroundColor: theme.palette.background.default,
    minHeight: '300px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    margin: '1rem',
    maxWidth: (!!cardModuleMaxWidth && `${cardModuleMaxWidth}px`) || '20rem',
  }),
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    textDecoration: 'none',
  },
  cardInner: {
    padding: '2.5rem 1.8rem 0',
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: -0.3,
  },
  description: {
    paddingTop: '1.5rem',
    color: theme.palette.text.tertiary,
  },
  imageContainer: {
    width: '100%',
    paddingTop: '2rem',
  },
}))

const AnnouncementCard = (props) => {
  const classes = useStyles(props)
  const isInEditor = useContext(PageContext).isInEditor

  const internalLink = !!props.blok.link && props.blok.link.linktype === 'story'
  const url = !!props.blok.link && multilinkToUrl(props.blok.link, isInEditor)
  const opensInNewTab =
    !internalLink && !startsWith(url, 'https://construction.autodesk.com')

  const CardWrapper = (props) =>
    internalLink ? (
      <GatsbyLink to={url} className={classes.link}>
        {props.children}
      </GatsbyLink>
    ) : (
      <a
        href={url}
        target={opensInNewTab ? '_blank' : '_self'}
        className={classes.link}
      >
        {props.children}
      </a>
    )

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.cardOuter}>
        <CardWrapper>
          <Box className={classes.cardInner}>
            {!!props.blok.title && (
              <H4 className={classes.title}>{props.blok.title}</H4>
            )}
            {!!props.blok.logo && (
              <Img alt={props.blok.logo.alt} src={props.blok.logo.filename} />
            )}
            {!!props.blok.description && (
              <MarkdownHandler className={classes.description}>
                {props.blok.description}
              </MarkdownHandler>
            )}
          </Box>
          {!!props.blok.image.length && (
            <Box className={classes.imageContainer}>
              {renderBloks(props.blok.image)}
            </Box>
          )}
        </CardWrapper>
      </Box>
    </SbEditable>
  )
}

export default AnnouncementCard
